import React, {useContext, useEffect, useRef, useState} from "react";
import {Box, Typography} from "@mui/material";
import Stack from "@mui/system/Stack";
import {Scrollbars} from "react-custom-scrollbars-2";
import AddIcon from "@mui/icons-material/Add";
import {MuiButtonCustom, MuiChipCustom, SkeletonCustom, theme} from "../theme";
import {Swiper, SwiperSlide} from "swiper/react";
import {EffectCoverflow, Navigation, Pagination} from "swiper/modules";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import {BottomBar} from "../widgets/bottom-bar";
import {TopBar} from "../widgets/top-bar";
import {LeftDrawer} from "../widgets/drawer";
import TitleText from "../widgets/title-text";
import {CompanyContext, OrderContext} from "../context";
import {useNavigate} from "react-router-dom";
import type {Swiper as SwiperClass} from "swiper/types";
import axios from "axios";
import Company from "../model/company";

export function MainPage() {
    const navigate = useNavigate();
    const [order, setOrder] = useContext(OrderContext);
    const [company, setCompany] = useContext(CompanyContext);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [swiper, setSwiper] = useState<SwiperClass>();

    useEffect(() => {
        axios.get(`https://api.carddevgroup.ru/api/v1/public/${order.companyId}/info`)
            .then((r) => {
                const company: Company = r.data;
                setCompany(company);
            })
            .catch(() => {});
    }, [order.companyId, setCompany]);

    let images = company?.images.concat(order.addedImages) ?? [];
    return (
        <Box>
            <Stack>
                <LeftDrawer/>
                <TopBar main/>
                <TitleText
                    title="Электронная подарочная карта"
                    sx={{
                        mt: '0.875rem',
                        mx: '2rem',
                        alignSelf: 'start'
                    }}
                />
                {
                    images.length > 1 ? <Box mt="1rem">
                        <Swiper
                            effect={"coverflow"}
                            grabCursor={true}
                            spaceBetween={0}
                            centeredSlides={true}
                            centeredSlidesBounds={images.length > 3}
                            centerInsufficientSlides={true}
                            loop={images.length > 2}
                            slidesPerView={1.25}
                            initialSlide={order.image ? images.indexOf(order.image) : 0}
                            coverflowEffect={{
                                rotate: 0,
                                stretch: 20,
                                depth: 0,
                                modifier: 1,
                                slideShadows: true,
                                scale: 0.8
                            }}
                            pagination={{el: ".swiper-pagination", clickable: true, dynamicBullets: true,}}
                            modules={[EffectCoverflow, Pagination, Navigation]}
                            className="swiper-images"
                            onSwiper={setSwiper}
                            onSlideChange={() => {
                                const o = {
                                    ...order,
                                    image: images[swiper?.activeIndex]
                                };
                                setOrder(o);
                            }}
                        >
                            {
                                images.map((image) => (
                                    <SwiperSlide
                                        key={image}
                                    >
                                        <img src={image} alt="slide_image"/>
                                    </SwiperSlide>
                                ))
                            }
                        </Swiper>
                    </Box> : images.length > 0 ? <img
                        src={images[0]}
                        alt={"card_image"}
                        style={{
                            marginTop: "1rem",
                            marginLeft: "2rem",
                            marginRight: "2rem",
                            borderRadius: "1rem",
                            aspectRatio: 1.586,
                            width: "80%",
                            alignSelf: "center"
                        }}
                    /> : <Box
                        sx={{
                            mx: "2rem",
                            mt: "1rem",
                            width: "80%",
                            aspectRatio: 1.586,
                            alignSelf: "center"
                        }}
                    >
                        <SkeletonCustom
                            sx={{
                                height: "100%"
                            }}
                        />
                    </Box>
                }
                {
                    images.length > 1 ? <Box mt="0.5rem">
                        <div className="slider-controller">
                            <div className="swiper-pagination"></div>
                        </div>
                    </Box> : null
                }
                <Box
                    sx={{
                        mt: "0.7rem",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <MuiButtonCustom
                        variant="contained"
                        startIcon={<AddIcon/>}
                        disabled={company === null}
                        onClick={() => {
                            fileInputRef.current?.click();
                        }}
                    >
                        Мой дизайн
                    </MuiButtonCustom>
                </Box>
                <Box
                    sx={{
                        mt: "1.75rem",
                        mx: "2rem"
                    }}
                >
                    <Typography
                        variant="h2"
                        color={theme.palette.primary.main}
                    >
                        Выберите номинал
                    </Typography>
                </Box>
                {
                    company === null ? <SkeletonCustom
                        sx={{
                            height: "2rem",
                            mx: "2rem",
                            mt: "1rem",
                            mb: "5rem"
                        }}
                    /> : <Box
                        mt="1rem"
                        mb="5rem"
                    >
                        <Scrollbars
                            style={{
                                width: "100%",
                                height: "3rem"
                            }}
                            renderTrackHorizontal={({style, ...props}) => {
                                return <div {...props} style={{
                                    ...style,
                                    left: "2rem",
                                    right: "2rem",
                                    bottom: 0,
                                    backgroundColor: "#D9D9D9",
                                    height: "0.25rem",
                                    borderRadius: "0.125rem"
                                }}/>;
                            }}
                            renderThumbHorizontal={({style, ...props}) => {
                                return <div {...props} style={{
                                    ...style,
                                    backgroundColor: theme.palette.primary.main,
                                    height: "0.25rem",
                                    borderRadius: "0.125rem"
                                }}/>;
                            }}
                            autoHide
                        >
                            <Stack
                                direction="row"
                                spacing="0.5rem"
                            >
                                <Box sx={{minWidth: "1.5rem"}}/>
                                {company.nominals.map((data) => {
                                    return (
                                        <MuiChipCustom
                                            key={data.id}
                                            label={`${data.value} ₽`}
                                            color={order.nominal?.id === data.id ? "primary" : undefined}
                                            sx={{
                                                color: order.nominal?.id === data.id ? "#ffffff" : theme.palette.primary.main
                                            }}
                                            onClick={() => {
                                                const o = {
                                                    ...order,
                                                    nominal: data
                                                };
                                                setOrder(o);
                                            }}
                                        />
                                    );
                                })}
                                <Box sx={{minWidth: "1.5rem"}}/>
                            </Stack>
                        </Scrollbars>
                    </Box>
                }
            </Stack>
            <Box
                maxWidth="md"
                position="fixed"
                bottom="0%"
                width="100%"
                zIndex={theme.zIndex.drawer + 2}
            >
                <BottomBar
                    progress={33}
                    caption="1/3"
                    route={order.nominal && order.image ? "/wish" : null}
                />
            </Box>
            <label>
                <input
                    hidden
                    ref={fileInputRef}
                    type='file'
                    accept=".jpg, .jpeg, .png"
                    capture="environment"
                    onChange={(event) => {
                        if (event.target.files && event.target.files[0]) {
                            navigate(
                                '/image_cropper',
                                {
                                    state: {
                                        imageUrl: URL.createObjectURL(event.target.files[0])
                                    }
                                }
                            );
                        }
                    }}
                />
            </label>
        </Box>
    );
}
